header.navbar {
  height: 40px;
  margin: 5px 20px;
}

div.menu .menu-item.tile.tile-centered {
  margin-top: 5px;
  max-width: 25px;
}

.imagenes {

    min-width: 80vw;
}

.menu.hero-list {
  z-index: inherit;
  -webkit-transform: none;
          transform: none;
  min-height: 100px;
  min-width: 75px;
}



img{
  max-width: 25%;
}



footer {
  position: fixed;
  bottom: 0;
  font-size: 14px;
}

@media screen and (min-width: 800px){
  img{
    max-width: 50%;
  }
}

@media screen and (max-width: 370px){
.menu.hero-list {
  min-width: 30px;

}


}
